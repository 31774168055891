import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "CapeRock"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="ケープロックウェブサイト"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        {/* <img style={{ margin: 0 }} src="./GatsbyScene.svg" alt="Gatsby Scene" /> */}

        <p>
          Ruby on
          Railsの開発を中心として活動しているフリーランスエンジニアです。
        </p>

        <h2>About</h2>

        <ul>
          <li>氏名：岩崎　匡寿（まさとし）</li>
          <li>屋号：ケープロック（Caperock)</li>
        </ul>

        <p></p>
        <h2>得意な技術領域</h2>

        <ul>
          <li>Ruby on Rails</li>
          <li>AWS</li>
          <li>Vue.js</li>
        </ul>

        <h2>過去の登壇・発表資料</h2>
        <p>
          <a href="https://speakerdeck.com/mstshiwasaki/sabisukai-fa-chu-qi-false-shi-jian-wojin-demai-u-ji-shu">
            サービス開発初期の「時間を金で買う」技術
          </a>{" "}
          / <a href="https://railsdm.github.io/">RailsDM 2019</a>
        </p>
        <p>
          <a href="https://speakerdeck.com/mstshiwasaki/hutuufalserailskai-fa-wosok-kerutameni">
            ふつうのRails開発を続けるために
          </a>
          /{" "}
          <a href="https://techplay.jp/event/621096">
            「ふつう」のRuby on Rails ウェブサービス開発（Clipla x
            みんなのウェディング）
          </a>
        </p>
      </Layout>
    )
  }
}

export default IndexPage
